import { NextPage } from 'next'
import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { Button, Container, Flex, Text, VStack } from 'ui'

const NotFoundPage: NextPage = () => {
    const router = useRouter()

    const handleRedirectHome = () => {
        router.replace('/')
    }

    return (
        <Container
            css={{
                p: '100rem 0'
            }}
        >
            <NextSeo title="Vault - 404" />
            <VStack spacing="4">
                <Flex
                    css={{
                        height: '120rem'
                    }}
                >
                    <Text
                        color="deep_aqua"
                        weight="bold"
                        css={{
                            fontSize: '120rem'
                        }}
                    >
                        4
                    </Text>
                    <Text
                        color="white"
                        weight="bold"
                        css={{
                            fontSize: '120rem'
                        }}
                    >
                        0
                    </Text>
                    <Text
                        color="deep_aqua"
                        weight="bold"
                        css={{
                            fontSize: '120rem'
                        }}
                    >
                        4
                    </Text>
                </Flex>
                <Text as="p" size="5" weight="bold" textTransform={'upper'}>
                    Whoops! Sorry Page Not Found
                </Text>

                <Button color="primary" size="lg" onClick={handleRedirectHome}>
                    return home
                </Button>
            </VStack>
        </Container>
    )
}

export default NotFoundPage
